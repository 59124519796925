import React from "react";
import "./Partners.scss";
import PartnersSwipper from "../PartnersSwipper/PartnersSwipper";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const { t } = useTranslation();

  return (
    <div className='section-two'>
      <div className='wrapper'>
        <p className='trusted-title'>
          {t("Trusted by Some of the Most Established Names")}
        </p>
        <PartnersSwipper />
      </div>
    </div>
  );
};

export default Partners;
