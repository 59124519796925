import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    zh: {
      translation: {
        Home: "首页",
        Company: "公司",
        Catalog: "目录",
        "Request for Pricing": "询价",
        "Dairy & Egg": "乳制品和鸡蛋",
        Brands: "品牌",
        Contact: "接触",
        Careers: "职业机会",
        Grocery: "杂货店",
        "Leading the Way in Canada’s Asian Food Industry for Over 50 Years":
          "50多年一直帶領加拿大亞洲食品行業",
        "Discover delight with Toronto Sun Wah, your trusted partner for the finest Asian ingredients for your culinary success.":
          "探索多倫多新華如何成為您最可靠的優質亞洲食材供應夥伴，為您成功的路上帶來無限驚喜",
        "Authentic ingredients at your fingertips": "正宗食材觸手可及",
        "Our extensive product catalog, meticulously curated to meet the needs of Asian cuisines at their finest":
          "我們精心挑選的豐富產品目錄，可滿足各種亞洲菜式的不同需求",
        "Browse Our Catalog": "瀏覽我們的產品目錄",
        "Product Marketing Experts": "產品銷售專家",
        "Helping global brands and established manufacturers expand their market reach into Canada, one of the fastest-growing Asian populations in North America":
          "我們幫助全球國際品牌和製造商拓展加拿大這個為北美其中一個亞洲人口成長最快速的國家市場",
        "Talk to us now": "聯繫我們",
        "Your trusted partner": "您可信賴的合作夥伴",
        "Comprehensive product catalog, exceptional service, and a legacy of always putting clients first define our approach to meeting all Asian food distribution needs.":
          "全面的產品目錄、卓越的服務以及將客戶放在第一位的傳統是我們對所有亞洲食品客戶的座右銘。  客戶",
        "Explore our site": "了解我們的網站",
        "Trusted by Some of the Most Established Names":
          "深受業內知名的企業信賴",
        "Explore the breadth of our selection, catering to each and every culinary need.":
          "了解我們豐富的選擇，必定能滿足每一種菜式的需求。",
        "Produce - Grocery - Meat & Seafood - Essentials - Bean Sprout - Packaging - Sanitation - Equipment - Other":
          "新鮮農產品 - 雜貨 - 肉類和海鮮 -日常用品-芽菜 - 包裝用品 -清潔用品 – 器材 - 其他",
        "Sushi Delights": "壽司的樂趣",
        "Craft Perfect Sushi": "手握完美壽司",
        "Stock up on essential sushi ingredients": "壽司材料的備貨",
        "Seaweed, Premium Sushi Rice, Crab Sticks, Cucumber":
          "紫菜、高級壽司米、蟹棒、青瓜",
        "Quote your Oriental Food today!": "查詢報價",
        "Wonton Soup Wonders": "雲吞麵的奧秘",
        "Savor Authentic Flavours": "品嚐正宗風味",
        "All You Need for a Heartwarming Bowl": "炮製一碗暖心的餸菜",
        "Wonton Wraps, Ground Pork, Green Onions,  Soy Sauce":
          "雲吞皮、豬肉碎、青蔥、醬油",
        "Spring Roll Specials": "特選春卷",
        "Roll into Freshness": "捲出新鮮感",
        "Get everything for Spring Rolls": "製作春卷的所需材料",
        "Wraps, Fresh Cabbage, Carrots, Ground Pork, Soy Sauce":
          "春卷皮、新鮮椰菜、红蘿蔔、豬肉碎、醬油",
        "Chicken Fried Rice Fiesta": "雞肉炒飯嘉年華",
        "Master It": "掌握炒飯的精髓",
        "Everything for a Flavorful Dish": "全為了一道美味佳餚",
        "Jasmine Rice, Chicken Breast, Frozen Peas, Canola Oil":
          "茉莉香米、雞胸肉、冰青豆、葵花籽油",
        "Our story": "關於我們",
        "Pioneering and leading Asian food importer, producer, and distributor in Canada.":
          "加拿大的亞洲食品進口商、生產商和分銷商的先鋒",
        "Since 1973, Toronto Sun Wah has been at the forefront of the Asian food industry. With a legacy rooted in always putting clients first, we have grown to become more than a distributor – we’re your strategic partner for business success.":
          "自1973年以來，多倫多新華一直走在亞洲食品產業的前端。憑藉將客戶放在第一位的傳統觀念，我們不僅是食品供應商，亦是您業務成功的策略合作夥伴。",
        "Learn more": "了解更多",
        "Harness Five Decades of Expertise": "超過五十年的專業知識",
        "Authenticity, integrity, and trust. We have built business relationships spanning over 50 years based upon these 3 principles.":
          "50 多年的業務關係，基於這 3 項原則, 貨真價實、誠實和信任。",
        "Our portfolio ranges from established legacy brands to promising niche brands. Explore our brand catalog and find the perfect ingredient that best suits your needs.":
          "我們的產品組合涵蓋從知名的傳統品牌到新興的小眾品牌。探索我們的品牌目錄並找到最適合您需求的完美材料",
        "Browse by brand": "品牌瀏覽",
        "Why Choose Us": "我們的優勢",
        "Why Toronto Sun Wah Stands Apart": "為何多倫多新華能脫穎而出",
        "Pioneering Innovation": "開拓創新",
        "Evolving for Excellence": "不斷發展，追求卓越",
        "Our commitment to innovation ensures we continually evolve, bringing you superior service and the latest in the food industry.":
          "我們對創新的承諾確保我們不斷發展，為您帶來卓越的服務和食品行業的最新資訊。",
        "Market Insights and Food Trends": "領先潮流",
        "Ahead of the Curve": "市場洞察和食品趨勢",
        "With our deep insights into market trends and evolving food preferences, we help you stay ahead in the culinary world.":
          "我們深入洞察最新市場趨勢和食客對食品要求的不斷變化，讓我們幫助您在行業內保持領先地位。",
        "Proven Product Expertise": "成熟的產品專業知識",
        "Your Culinary Consultants": "您的餐飲顧問",
        "Rely on our experienced sales team’s product expertise to find the perfect ingredients for your needs.":
          "我們經驗豐富的銷售團隊擁有專業產品知識，能協助您找到您所需的完美材料。",
        "Operational Excellence": "卓越營運",
        "Decades of Trusted Service": "數十年值得信賴的服務",
        "With 50 years of experience, our operational excellence ensures reliability and peace of mind in every transaction.":
          "憑藉 50 多年的經驗，我們卓越的營運確保每筆交易的可靠性，您可以安心把食材交給我們。",
        "Trusted Advisors With First-Class Service":
          "值得信賴的顧問，為您提供一流的服務",
        "Your Culinary Journey, Elevated": "攜手走向更上一層樓的餐飲之旅",
        "Beyond a supplier, we’re your trusted advisors, committed to excellence in every interaction. Expect first-class service that attentively meets your needs, big or small, at every step of your journey with us.":
          "除了作為您的供應商，我們還是您值得信賴的顧問，致力於在每次互動中提供卓越服務。在我們一同經歷的餐飲旅程的每一步，我們貼心滿足您無論大小的需求，讓您時刻享受一流的服務。",
        "Unmatched Customer Service": "無與倫比的客戶服務",
        "Dedicated Team, Personalized Service": "專業團隊，個人化服務",
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
