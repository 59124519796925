import React, { useEffect, useState } from "react";
import "./CategoriesFilter.scss";
import { Button } from "@mui/material";
import { AllCategories } from "../ShopCompnents/CategoriesSwipper/CategoryData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { motion } from "framer-motion";

const CategoriesFilter = ({ categories }) => {
  const navigate = useNavigate();

  // const findSubCatById = (categoryId) => {
  //   const targetCategory = AllCategories.find(
  //     (cat) => cat.children === categoryId
  //   );
  //   if (targetCategory) {
  //     return targetCategory.children;
  //   } else {
  //     return [];
  //   }
  // };

  const [categorySelected, setCategorySelected] = useState(null);

  useEffect(() => {
    setCategorySelected(categories[2]);
  }, [categories]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.5 }}
      transition={{ ease: "easeOut", duration: 0.3 }}
      className='catgeories-filter'>
      <div className='inside-wrapper'>
        <div className='tabs'>
          {categories.map((category, index) => (
            <Button
              className={`tab-bttn ${
                categorySelected?.parent === category?.parent ? "active" : ""
              }`}
              key={index}
              onClick={() => setCategorySelected(category)}>
              {category?.parent}
            </Button>
          ))}
        </div>
        <div
          className='subCategory-wrap'
          style={{
            maxWidth:
              categorySelected?.children?.length < 3
                ? categorySelected?.children?.length * 295 + "px"
                : "933px",
          }}>
          {categorySelected && (
            <Swiper
              slidesPerView={"auto"}
              navigation={
                Array.isArray(categorySelected.children) &&
                categorySelected.children.length > 3
              }
              pagination={true}
              modules={
                Array.isArray(categorySelected.children) &&
                categorySelected.children.length > 3
                  ? [Navigation, Pagination]
                  : [Pagination]
              }
              style={{ paddingBottom: "66px" }}>
              {categorySelected?.children.map((subcat) => (
                <SwiperSlide key={subcat.GroupName}>
                  <div
                    className='subCategory-box'
                    onClick={() =>
                      navigate(
                        `/catalog/${categorySelected.parent}/${subcat.GroupName}`
                      )
                    }>
                    <ReactSVG
                      src={subcat?.image}
                      alt={subcat?.GroupName}
                      className='cat-img'
                    />
                    <p className='categoryName'>{subcat?.GroupName}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default CategoriesFilter;
